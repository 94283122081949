.home-light-theme .hero{height:auto;min-height:550px;display:flex;flex-direction:column;justify-content:center}
.home-light-theme .hero .prg-container{background-color:rgb(255 255 255 / 90%)}
.home-light-theme .hero .prg-count, .home-light-theme .hero .prg-count span{color:#f38020!important}
.home-light-theme .hero .prg-count-title{color:#232323!important}
.home-light-theme .dark{background-color:inherit!important}
.home-light-theme section.dark .sap-sm-heading a, .home-light-theme section.dark .sap-lg-heading, .home-light-theme .hww-list-inner .sap-sm-heading{color:#1C447D!important}
.home-light-theme .dark .text-para,.home-light-theme .serv-con,.home-light-theme .hww-con,.home-light-theme .wdi-heading{color:#232323!important}
.home-light-theme .serv-con,.home-light-theme .hww-con{font-size:14px;line-height:26px;font-weight:400;padding:0 0 15px}
.home-light-theme .serv-list-inner .sap-sm-heading{padding:30px 0 15px}
.home-light-theme .serv-btn svg path,.home-light-theme .serv-btn svg line,.home-light-theme .serv-btn svg #Ellipse_1{stroke:#232323!important}
.home-light-theme .serv-btn a{opacity:.7}
.home-light-theme .serv-list-head{padding:60px 0 0}
.home-light-theme .services .get-quote button{background-color:#fff;color:#232323!important}
.home-light-theme .get-quote button:before{content:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='34.384' height='23.813' viewBox='0 0 34.384 23.813'%3E%3Cpath id='Path_229' data-name='Path 229' d='M41.329,0c-3.982,6.31,4.42,23.813-30.945,23.813H41.329S49.066,0,41.329,0Z' transform='translate(-10.384)' fill='%23fff'/%3E%3C/svg%3E%0A") !important;left:-18px !important;}
.home-light-theme .get-quote button:after{content:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='34.383' height='23.813' viewBox='0 0 34.383 23.813'%3E%3Cpath id='Path_228' data-name='Path 228' d='M3.439,0C7.421,6.31-.981,23.813,34.384,23.813H3.439S-4.3,0,3.439,0Z' transform='translate(-0.001)' fill='%23fff'/%3E%3C/svg%3E%0A") !important;}
.home-light-theme .services.dark,.home-light-theme .techwork,.home-light-theme .hww,.home-light-theme .testi,.home-light-theme .we-deals{padding-bottom:0}
.home-light-theme .services-inner,.home-light-theme .techwork-inner,.home-light-theme .hww-inner,.home-light-theme .testi-inner,.home-light-theme .we-deals-inner{border-bottom:1px solid rgb(35 35 35 / 15%);padding-bottom:80px}
.home-light-theme section{padding:80px 0}
.home-light-theme .rocket-up .fly-rocket path,.home-light-theme .hww-icon svg path,.home-light-theme .serv-icon svg path,.home-light-theme .wdi-icon svg path{fill:#F38020}
.home-light-theme .get-quote{top: -58px;}
.home-light-theme .hero:before{display:none}
.home-light-theme .hww-icon svg{background-color:#fff!important}
.home-light-theme .hww-list-head .dotted-line path{stroke:#F38020}
.home-light-theme .hww-list-inner .sap-sm-heading{padding:30px 0 15px}
.home-light-theme .hww-list-inner .sap-sm-heading .hww-num{color:rgb(35 35 35 / 40%)!important;top:25px}
.home-light-theme .hww-list-head{padding:60px 0 0}
.home-light-theme .hww-list-head .dotted-line{top:70px}
.home-light-theme .testi-inner:after{display:none}
.home-light-theme .wdi-float-icon svg path{fill:#C9C9C9}
.home-light-theme .sap-style-heading svg path{fill:#C9C9C9;stroke:#C9C9C9}
.home-light-theme .home-form.light{background-color:inherit;border-bottom:1px solid rgb(35 35 35 / 15%)}
.home-light-theme .odo-scroll-word{color:#F38020}
.home-light-theme .home-form .form-control{border-color:#C9C9C9;color:#232323}
.home-light-theme .home-form .form-control::placeholder{color:#C9C9C9}
.home-light-theme .home-form-float-icon svg path{fill:#C9C9C9}
.home-light-theme .home-form.light .sap-caption{-webkit-text-fill-color:transparent;background:-webkit-linear-gradient(0deg,#f38020,#2491cc);-webkit-background-clip:text}
.home-light-theme .foot-left{padding:60px 60px 0 0}
.home-light-theme .foot-kbit-info{padding:0 0 60px}
.home-light-theme .foot-links-head{padding:60px 0 0}
.home-light-theme .foot-social-links li a i{padding:50px}
.home-light-theme .foot-right{padding:60px 0 0}
.home-light-theme .foot-sub{padding:0 0 60px 60px}
.home-light-theme .foot-kbit-info:after{right:-60px}
.home-light-theme .services {padding-top: 10px;padding-bottom: 0;}